import {onLoad} from "../base/onLoad";
import {Splide} from "@splidejs/splide";

onLoad(() => {
    const slider = document.querySelector('.team-slider');

    if (!slider) {
        return;
    }

    const sliderInstance = new Splide(slider, {
        perPage: 5,
        focus: 'center',
        gap: '4rem',
        breakpoints: {
            640: {
                perPage: 1,
            },
            1400: {
                perPage: 3,
            }
        }
    });

    sliderInstance.mount();
});