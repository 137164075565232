import {onLoad} from "../base/onLoad";

onLoad(() => {
    const faqSections = document.querySelectorAll('[data-faq-section]');
    const faqCategories = document.querySelectorAll('[data-faq-cat]');

    if (faqCategories.length > 0 && faqSections.length > 0) {
        new FaqPageHandler(faqSections, faqCategories);
    }
});

class FaqPageHandler {
    constructor(sections, categories) {
        this.sections = sections;
        this.categories = categories;

        this.init();
    }

    init() {
        this.listeners();

        const firstCategory = this.categories[0] || null;
        if (firstCategory) {
            this.onCategoryClick(firstCategory.getAttribute('data-faq-cat'));
        }
    }

    listeners() {
        this.categories.forEach(category => {
            const cat = category.getAttribute('data-faq-cat');
            category.addEventListener('click', e => {
                this.onCategoryClick(cat);
            });
        });

        this.sections.forEach(section => {
            const items = section.querySelectorAll('.faq-item');
            items.forEach(item => {
                const header = item.querySelector('.faq-header');
                header.addEventListener('click', () => {
                    this.faqItemClick(items, item);
                });
            });
        });
    }

    faqItemClick(allItems, item) {
        allItems.forEach(i => this.hide(i));
        this.show(item);
    }

    deselectAllCategories() {
        this.categories.forEach(category => this.hide(category));
    }

    getCategory(category) {
        let output = null;
        this.categories.forEach(cat => {
            if (cat.getAttribute('data-faq-cat') === category) {
                output = cat;
            }
        });
        return output;
    }

    selectCategory(category) {
        const cat = this.getCategory(category);
        cat && this.show(cat);
    }

    hideSections() {
        this.sections.forEach(section => this.hide(section));
    }

    getSection(category) {
        let output = null;
        this.sections.forEach(section => {
            if (section.getAttribute('data-faq-section') === category) {
                output = section;
            }
        });
        return output;
    }

    showSection(category) {
        this.hideSections();
        const section = this.getSection(category);
        section && this.show(section);
    }

    show(item) {
        item.setAttribute('data-active', 'true');
    }

    hide(item) {
        item.setAttribute('data-active', 'false');
    }

    selectFirstItem(category) {
        const section = this.getSection(category);
        const faqItems = section.querySelectorAll('.faq-item');
        const firstItem = faqItems[0] || null;
        faqItems.forEach(item => {
            if (item === firstItem) {
                this.show(item);
            } else {
                this.hide(item);
            }
        });
    }

    onCategoryClick(category) {
        this.deselectAllCategories();
        this.selectCategory(category);
        this.showSection(category);
        this.selectFirstItem(category);
    }
}