import {onLoad} from "../base/onLoad";

onLoad(() => {
    const triggers = document.querySelectorAll('[data-cat-trigger]');
    const filterItems = document.querySelectorAll('[data-filter-cat]');

    new CategoryFilter(triggers, filterItems);
})

class CategoryFilter {
    /**
     * @param {NodeList} triggers
     * @param {NodeList} items
     */
    constructor(triggers, items) {
        this.triggers = triggers;
        this.items = items;

        this.triggerSetup();

        this.render();
    }

    setActive = e => e.classList.add('active');
    setInactive = e => e.classList.remove('active');
    isActive = e => e.classList.contains('active');
    deselectTriggers = () => this.triggers.forEach(t => this.setInactive(t));

    selectFirst() {
        this.deselectTriggers();
        if(this.triggers.length > 0) {
            const i = this.triggers.item(0);
            i && this.setActive(i);
        }
    }

    triggerSetup() {
        this.selectFirst();
        this.triggerEvents();
    }

    triggerEvents() {
        this.triggers.forEach(trigger => {
            trigger.addEventListener('click', () => {
                if(this.isActive(trigger)) {
                    this.selectFirst();
                } else {
                    this.deselectTriggers();
                    this.setActive(trigger);
                }

                this.render();
            });
        });
    }

    getFilterValue() {
        /**
         * @type {HTMLElement|null}
         */
        let activeTrigger = null;
        this.triggers.forEach(t => {
            if(this.isActive(t)) {
                activeTrigger = t;
            }
        });

        if(!activeTrigger) {
            return false;
        }

        return activeTrigger.getAttribute('data-cat-trigger');
    }

    render() {
        const showAllItems = () => this.items.forEach(i => this.setActive(i));
        const hideAllItems = () => this.items.forEach(i => this.setInactive(i));
        const filterValue = this.getFilterValue();

        if(!filterValue) {
            showAllItems();
            return;
        }

        hideAllItems();

        this.items.forEach(i => {
            const categories = i.getAttribute('data-filter-cat');
            if(!categories)
                return;

            const catArray = categories.split(',');

            if(catArray.includes(filterValue)) {
                this.setActive(i);
            } else {
                this.setInactive(i);
            }
        })
    }
}