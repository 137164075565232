import {onLoad} from "../base/onLoad";

class FaqSection {
    /**
     * @param {HTMLElement} element
     * @param element
     */
    constructor(element) {
        this.element = element;

        this.items = this.element.querySelectorAll('.faq-item');

        this.init();
    }

    init() {
        this.listeners();

        const firstItem = this.items[0] || null;
        if (firstItem) {
            this.show(firstItem);
        }
    }

    hideAll() {
        this.items.forEach(item => {
           item.setAttribute('aria-expanded', 'false');
              item.setAttribute('data-active', 'false');
        });
    }

    show(item) {
        this.hideAll();
        item.setAttribute('aria-expanded', 'true');
        item.setAttribute('data-active', 'true');
    }

    is_visible(item) {
        return item.getAttribute('aria-expanded') === 'true';
    }

    listeners() {
        this.items.forEach(item => {
            const header = item.querySelector('.faq-header');

            header.addEventListener('click', () => {
               if(this.is_visible(item)) {
                   this.hideAll();
               } else {
                 this.show(item);
               }
            });
        });
    }
}

onLoad(() => {
   const faqSections = document.querySelectorAll('.faq-section');
   faqSections.forEach(faqSection => new FaqSection(faqSection));
});